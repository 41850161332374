import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Articles from '../data/articles';

import articleStyles from './articleLink.module.css';

const ArticleLinks = () => {
  const articleImages = useStaticQuery(graphql`
    query ArticleImageQuery {
      allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "images/articles"}}) {
        edges {
          node {
            base
            name
            childImageSharp {
              fluid(quality: 70, maxWidth: 800) {
                aspectRatio
                src
                srcSet
                sizes
                tracedSVG
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
    }
  `);

  const images = articleImages.allFile.edges;

  return (
    <div className={articleStyles.articleLinkContainer}>
      {Articles.map((article) => {
        const articleImage = images.find((image) => (image.node.name === article.image));
        const sampleText = article.text.join().substring(0, 300);

        return (
          <Link className={articleStyles.articleLink} key={`text_${article.link}`} to={`/articles/${article.link}/`}>
            <div className={articleStyles.articleLinkImage}>
              <Img
                fluid={articleImage.node.childImageSharp.fluid}
                alt={article.alt}
                fadeIn
              />
            </div>
            <div className={articleStyles.articleLinkHeader}>
              <span>{article.label}</span>
              <span className={articleStyles.articleLinkCentre} />
              <span>{'>'}</span>
            </div>
            <div className={articleStyles.articleLinkText}>
              {`${sampleText} ...`}
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default ArticleLinks;
