import React from 'react';

import Layout from '../../components/layout';
import Menu from '../../components/menuComponent';
import ArticleLinkComponent from '../../components/articleLinkComponent';

export default () => (
  <Layout
    title="Articles based on our extensive interior design knowledge | Wow Interior Design"
    description="Delve into Wow interior's 25 years of design & installation knowledge of fitted furniture to help when making decisions creating your new dream room."
  >
    <Menu />
    <ArticleLinkComponent />
  </Layout>
);
